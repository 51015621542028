import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../utils/AxiosInstance";
import Sidebar from "../components/Sidebar";
import { useNavigate } from "react-router";
import {
  GET_ONGA_LOCATIONS,
  GET_OUTLET,
  GET_STATES,
  GET_LGAs,
  GET_ALL_COUNTRIES,
} from "../utils/constant";
import { GET_OUTLETCHANNEL } from "../utils/constant";
import { CREATE_WEB_OUTLET, PROFILE } from "../utils/constant";
import ModalLoader from "../components/modal/loader";
import TitleButton from "../components/buttons";
import { toast } from "react-toastify";

const CreateOutlet = () => {
  const [imageObject, setImageObject] = useState(null);
  const [outlet, setOutletType] = useState([]);
  const [outletChannel, setOutletChannel] = useState([]);
  const [outletName, setOutletName] = useState("");
  const [outletPhone, setOutletPhone] = useState("");
  const [outletAddress, setOutletAddress] = useState("");
  const [streetNo, setStreetNo] = useState("");
  const [streetName, setStreetName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cpp, setCPP] = useState("");
  const [newoutlet, setNewOutletType] = useState("");
  const [newoutletchannel, setNewOutletChannel] = useState("");
  const [onga, setOnga] = useState([]);
  const [selectedOnga, setSelectedOnga] = useState("");
  const [countryId, setCountryId] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [stateId, setStateId] = useState("");
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [lgas, setLgas] = useState([]);
  const [selectedLga, setSelectedLga] = useState("");
  const [loadingText, setLoadingText] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleFileInput = useRef(null);
  const Navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch outlet and outlet channel data
        const [outletResponse, outletChannelResponse] = await Promise.all([
          axiosInstance.get(GET_OUTLET),
          axiosInstance.get(GET_OUTLETCHANNEL),
        ]);

        setOutletType(outletResponse.data.data);
        setOutletChannel(outletChannelResponse.data.data);
        setLoading(false); // Set loading to false after data fetching is done
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(GET_ALL_COUNTRIES);
        setCountries(response.data.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchStates = async () => {
      if (!selectedCountry) {
        setStates([]);
        return;
      }
      try {
        const response = await axios.post(GET_STATES, {
          country_id: selectedCountry.toString(),
        });
        setStates(response.data.data);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };
    fetchStates();
  }, [selectedCountry]);

  useEffect(() => {
    const fetchLGA = async () => {
      if (!selectedState) {
        setLgas([]);
        return;
      }
      try {
        const response = await axios.post(GET_LGAs, {
          state_id: selectedState.toString(),
        });
        setLgas(response.data.data);
      } catch (error) {
        console.error("Error fetching LGAs:", error);
      }
    };
    fetchLGA();
  }, [selectedState]);

  useEffect(() => {
    const fetchOnga = async () => {
      if (!selectedLga) {
        setOnga([]);
        return;
      }
      // Extract location_id from the selectedLga object (assuming it has a property named 'id')
      const locationId = selectedLga;
      try {
        const response = await axios.get(GET_ONGA_LOCATIONS, {
          params: { location_id: locationId },
        });
        setOnga(response.data.data);
      } catch (error) {
        console.error("Error fetching Onga locations:", error);
      }
    };

    // Call fetchOnga only when selectedLga changes (assuming it's an object with properties)
    fetchOnga();
  }, [selectedLga]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch country and state IDs from the same API endpoint
        const response = await axiosInstance.get(PROFILE);
        const data = response.data.data;
        const reg_info = data.reg_info;
        const employee = reg_info.employee;
        console.log(employee);
        setCountryId(employee.country.id);
        setStateId(employee.state.id);
        console.log(employee.country.id, employee.state.id);
      } catch (error) {
        console.error("Error fetching country and state IDs:", error);
      }
    };

    fetchData();
  }, []);

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log(selectedFile);
    if (!selectedFile) return; // Exit if no file selected

    const setImage = (url) => {
      // Helper function to set the image preview
      setImageObject({
        imagePreview: url,
        imageFile: selectedFile,
      });
    };

    if (selectedFile.size > 10 * 1024 * 1024) {
      // Adjust the file size threshold as needed
      // Read large files in chunks using setTimeout
      let offset = 0;
      const chunkSize = 2 * 1024 * 1024; // Adjust the chunk size as needed

      const readChunk = () => {
        const reader = new FileReader();
        const blob = selectedFile.slice(offset, offset + chunkSize);
        reader.readAsDataURL(blob);
        reader.onload = (e) => {
          const result = e.target.result;
          if (result.startsWith("data:")) {
            setImage(result);
          }
          offset += chunkSize;
          if (offset < selectedFile.size) {
            setTimeout(readChunk, 0);
          }
        };
      };

      setTimeout(readChunk, 0);
    } else {
      // For smaller files, use URL.createObjectURL for better performance
      const url = URL.createObjectURL(selectedFile);
      setImage(url);
    }
  };

  const handleCountryChange = (e) => {
    const countryId = +e.target.value;
    setSelectedCountry(countryId);
    setSelectedState("");
    // Store selected country ID in both localStorage and sessionStorage
    sessionStorage.setItem("selectedCountryId", countryId);
  };

  const handleStateChange = (e) => {
    const stateId = +e.target.value;
    setSelectedState(stateId);
    // Store selected state ID in both localStorage and sessionStorage
    sessionStorage.setItem("selectedStateId", stateId);
  };

  const handleLGAChange = (e) => {
    const LGA = +e.target.value;
    setSelectedLga(LGA);
    console.log(LGA);
    sessionStorage.setItem("selectedLGA", LGA);
  };

  const handleOngaChange = (e) => {
    const Onga = +e.target.value;
    setSelectedOnga(Onga);
    console.log(Onga);
    sessionStorage.setItem("selectedOnga", Onga);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setLoadingText(true);

    try {
      let latitude;
      let longitude;

      // Retrieve user's geolocation
      if (!navigator.geolocation) {
        toast.error("Geolocation is not supported by this browser.");
        setLoading(false);
        setLoadingText(false);
        return;
      }

      navigator.geolocation.getCurrentPosition(async (position) => {
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;

        // Validate input fields
        if (!newoutlet) {
          toast.error("Select outlet type");
          setLoading(false);
          setLoadingText(false);
          return;
        }

        if (!newoutletchannel) {
          toast.error("Select Outlet channel");
          setLoading(false);
          setLoadingText(false);
          return;
        }

        if (
          !outletName ||
          !outletPhone ||
          !outletAddress ||
          !streetNo ||
          !streetName ||
          !firstName ||
          !lastName ||
          !cpp
        ) {
          toast.error("Please fill in all required fields");
          setLoading(false);
          setLoadingText(false);
          return;
        }

        const formData = new FormData();
        formData.append("type_id", newoutlet);
        formData.append("channel_id", newoutletchannel);
        formData.append("outlet_name", outletName);
        formData.append("outlet_phone", outletPhone);
        formData.append("outlet_address", outletAddress);
        formData.append("street_no", streetNo);
        formData.append("street_name", streetName);
        formData.append("cpf_name", firstName);
        formData.append("cpl_name", lastName);
        formData.append("is_bso", 1);
        formData.append("cpp", cpp);
        formData.append("gio_lat", latitude);
        formData.append("gio_long", longitude);
        formData.append("country_id", selectedCountry);
        formData.append("state_id", selectedState);
        formData.append("location_id", selectedLga);
        formData.append("onga_id", selectedOnga);

        if (imageObject && imageObject.imageFile) {
          formData.append("image", imageObject.imageFile);
        }

        const { data } = await axiosInstance.post(CREATE_WEB_OUTLET, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        toast.success("Successfully created");
        console.log("Response:", data);
        Navigate("/outlet-list");
        setLoadingText(false);
        setLoading(false);
      });
    } catch (error) {
      console.error(error?.response?.data?.data);
      toast.error(error.response.data.data, {
        position: "bottom-right",
      });
      setLoading(true);
      setLoadingText(false);
    }
  };

  const handleClick = () => {
    handleFileInput.current.click();
  };

  return (
    <div>
      <ModalLoader visible={loading} />
      <div style={{ width: "100%", marginBottom: "20px" }}>
        <Sidebar />
      </div>

      <div
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <form onSubmit={handleSubmit}>
          <label htmlFor="outlet" style={{ color: "blue" }}>
            Outlet Classification
          </label>
          <select
            id="outlet"
            name="outletType"
            required
            onChange={(e) => {
              setNewOutletType(e.target.value);
              console.log(e.target.value);
            }}
            style={{ marginBottom: "10px" }}
          >
            <option value="outlet type">Outlet Type</option>
            {outlet.map((data) => (
              <option key={data.id} value={data.id}>
                {data.type_name}
              </option>
            ))}
          </select>

          <select
            id="outletChannel"
            name="outletChannel"
            required
            onChange={(e) => setNewOutletChannel(e.target.value)}
            style={{ marginBottom: "10px" }}
          >
            <option value="outlet channel">Outlet Channel</option>
            {outletChannel.map((data) => (
              <option key={data.id} value={data.id}>
                {data.channel_name}
              </option>
            ))}
          </select>

          <label htmlFor="outletName" style={{ color: "blue" }}>
            Basic Information
          </label>
          <input
            type="text"
            id="outletName"
            name="outletName"
            placeholder="Outlet Name"
            required
            onChange={(e) => setOutletName(e.target.value)}
            style={{ marginBottom: "10px" }}
          />
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Phone Number"
            maxlength="11"
            required
            onChange={(e) => setOutletPhone(e.target.value)}
            style={{ marginBottom: "10px" }}
          />
          <textarea
            id="note"
            name="note"
            placeholder="Note..."
            style={{ height: "100px", marginBottom: "10px" }}
          ></textarea>

          <label htmlFor="locationInfo" style={{ color: "blue" }}>
            Location Information
          </label>
          <input
            type="text"
            id="streetNumber"
            name="streetNumber"
            placeholder="Street Number"
            onChange={(e) => setStreetNo(e.target.value)}
            style={{ marginBottom: "10px" }}
          />
          <input
            type="text"
            id="streetName"
            name="streetName"
            placeholder="Street Name"
            onChange={(e) => setStreetName(e.target.value)}
            style={{ marginBottom: "10px" }}
          />
          <select className="mb-[10px]" onChange={handleCountryChange} required>
            <option value="">Select Country</option>
            {countries.map((country) => (
              <option key={country.id} value={country.id}>
                {country.country_name}
              </option>
            ))}
          </select>

          <select
            id="state"
            name="state"
            style={{ marginBottom: "10px" }}
            required
            onChange={handleStateChange}
          >
            <option value="">State</option>
            {states.map((state) => (
              <option key={state.id} value={state.id}>
                {state.state_name}
              </option>
            ))}
          </select>
          <select
            id="selectlga"
            name="select lga"
            style={{ marginBottom: "10px" }}
            required
            onChange={handleLGAChange}
          >
            <option value="">Select LGA</option>
            {lgas.map((lga) => (
              <option key={lga.id} value={lga.id}>
                {lga.location_name}
              </option>
            ))}
          </select>
          <select
            id="selectLocation"
            name="selectLocation"
            required
            onChange={handleOngaChange}
            style={{ marginBottom: "10px" }}
          >
            <option value="select location">Select Location</option>
            {onga.map((onga) => (
              <option key={onga.id} value={onga.id}>
                {onga.onga_area}
              </option>
            ))}
          </select>

          <label htmlFor="contactInfo" style={{ color: "blue" }}>
            Contact Information
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            placeholder="First Name"
            onChange={(e) => setFirstName(e.target.value)}
            style={{ marginBottom: "10px" }}
          />
          <input
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            onChange={(e) => setLastName(e.target.value)}
            style={{ marginBottom: "10px" }}
          />
          <input
            type="tel"
            id="contactPhoneNumber"
            name="contactPhoneNumber"
            placeholder="Phone Number"
            maxlength="11"
            required
            onChange={(e) => setCPP(e.target.value)}
            style={{ marginBottom: "12px" }}
          />

          <label style={{ color: "blue" }}>Outlet Image</label>
          <input
            type="file"
            accept="image/*"
            ref={handleFileInput}
            required
            onChange={handleImageChange}
          />
          {imageObject && <img src={imageObject.imagePreview} alt="Selected" />}

          <TitleButton
            title={loading ? "Loading" : "Save"}
            handleSubmit={handleSubmit}
          />
        </form>
      </div>

      {/* Media queries */}
      <style jsx>{`
        @media screen and (max-width: 768px) {
          .container {
            // Mobile styles
          }
        }
      `}</style>

      {/* Additional styles */}
      <style>{`
        body { font-family: Arial, Helvetica, sans-serif; }
        * { box-sizing: border-box; }
        input[type=text], select, textarea {
          width: 100%;
          padding: 12px;
          border: none;
          border-bottom: 1px solid #ccc;
          border-radius: 4px;
          margin-top: 6px;
          margin-bottom: 18px;
          resize: vertical;
        }
        input[type=submit] {
          background-color: #04AA6D;
          color: white;
          padding: 12px 20px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }
        input[type=submit]:hover {
          background-color: #45a049;
        }
        .container {
          border-radius: 5px;
          background-color: #f7fafc;
          padding: 20px;
          width: 80%; /* Adjust width as needed */
          margin: auto; /* Center the container horizontally */
        }
      `}</style>
    </div>
  );
};

export default CreateOutlet;
