import React, { useState } from "react";
import axiosInstance from "../../utils/AxiosInstance";
import CompleteVisitButton from "../../components/Completevisitbutton";
import Sidebar1 from "../../components/sidebar1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { INVENTORY_NOTE } from "../../utils/constant";
import { useNavigate } from "react-router-dom";

const InventoryNote = () => {
  const [newNote, setNote] = useState("");
  const [loadingText, setLoadingText] = useState(false);
  const navigate = useNavigate();

  const handleNote = (e) => {
    const newNote = e.target.value;
    setNote(newNote);
    console.log(setNote);
  };

  const handleSubmit = async (event) => {
    setLoadingText(true);
    event.preventDefault();

    if (!newNote) {
      toast.error("Please give your feedback.");
      setLoadingText(false);
      return;
    }

    const scheduleId = JSON.parse(sessionStorage.getItem("schedule_id"));
    const visitSchedules = JSON.parse(sessionStorage.getItem("visitSchedules"));
    const selectedSchedule = visitSchedules.find(
      (schedule) => schedule.schedule_id === scheduleId
    );
    console.log(scheduleId, "schedule_id");

    const formData = new FormData();
    formData.append("notes", newNote);
    formData.append("schedule_id", scheduleId);
    console.log(formData);

    try {
      const response = await axiosInstance.post(INVENTORY_NOTE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Response:", response.data);
      toast.success("Visit/Report Completed");
      navigate("/inventory-check");
    } catch (error) {
      toast.error(
        "Error in API call:",
        error.response || error.message || error
      );
      console.error("Error:", error);
      setLoadingText(false);
      // Handle error response
    } finally {
    }
  };

  return (
    <>
      <div style={{ width: "100%", marginBottom: "20px" }}>
        <Sidebar1 />
      </div>

      <div
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <form onSubmit={handleSubmit}>
          <label style={{ color: "blue" }}>Note (Feed Back)</label>
          <textarea
            id="note"
            name="note"
            placeholder="Write Something..."
            onChange={handleNote}
            required
            style={{ height: "100px", marginBottom: "100px" }}
          />
          <div className="flex justify-center gap-4">
            <CompleteVisitButton
              title={loadingText ? "loading...." : "Complete Visit"}
            />
            <button className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              <FontAwesomeIcon icon={faShareNodes} className="mr-2" />
              SHARE
            </button>
          </div>
        </form>
      </div>
      {/* Media queries */}
      <style jsx>{`
        @media screen and (max-width: 768px) {
          .container {
            // Mobile styles
          }
        }
      `}</style>

      {/* Additional styles */}
      <style>
        {`
          body { font-family: Arial, Helvetica, sans-serif; }
          * { box-sizing: border-box; }
          input[type=text], select, textarea {
            width: 100%;
            padding: 12px;
            border: none;
            border-bottom: 1px solid #ccc;
            border-radius: 4px;
            margin-top: 6px;
            margin-bottom: 16px;
            resize: vertical;
          }
          input[type=submit] {
            background-color: #04AA6D;
            color: white;
            padding: 12px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
          }
          input[type=submit]:hover {
            background-color: #45a049;
          }
          .container {
            border-radius: 5px;
            background-color: #f7fafc;
            padding: 20px;
            width: 80%; /* Adjust width as needed */
            margin: auto; /* Center the container horizontally */
          }
        `}
      </style>
    </>
  );
};

export default InventoryNote;
