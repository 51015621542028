import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const AddButton = ({ handleDescriptionChange }) => {
  const [inputs, setInputs] = useState([""]);

  const handleInputChange = (index, event) => {
    const newInputs = [...inputs];
    newInputs[index] = event.target.value;
    setInputs(newInputs);
    handleDescriptionChange(newInputs);
    console.log(newInputs);
  };

  const addInputField = () => {
    setInputs([...inputs, ""]);
  };

  return (
    <div className="mt-24 space-y-4">
      {inputs.map((input, index) => (
        <div key={index} className="flex items-center">
          <input
            type="text"
            placeholder="Field Staff Promo Description"
            value={input}
            onChange={(e) => handleInputChange(index, e)}
            required
            className="flex-1 p-2 border border-gray-300 rounded-l"
          />
          <button
            type="button"
            onClick={addInputField}
            className="p-3 bg-blue-500 text-white rounded-r border border-blue-500 h-full flex items-center justify-center"
          >
            <FontAwesomeIcon icon={faPlus} style={{ color: "#f4f5f5" }} />
          </button>
        </div>
      ))}
    </div>
  );
};

export default AddButton;
