import React, { useState } from "react";
import axios from "axios";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { UPDATE_KYC } from "../utils/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCamera } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import "react-toastify/dist/ReactToastify.css";

const Capture = () => {
  const [imageFile, setImageFile] = useState(null); // State for selected file
  const [imageUrl, setImageUrl] = useState(null); // State for captured/selected image URL
  const [guarantorFile, setGuarantorFile] = useState(null);
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [imagename, setImageName] = useState(null);
  const [loading, setLoading] = useState(false);

  const guarantorData = JSON.parse(sessionStorage.getItem("guarantor"));
  const personalListInfo = JSON.parse(
    sessionStorage.getItem("personalListInfo")
  );
  const bankData = JSON.parse(sessionStorage.getItem("bankListInfo"));
  const dataToStore = JSON.parse(sessionStorage.getItem("uniqueList"));
  const id = JSON.parse(sessionStorage.getItem("id"));
  const email = JSON.parse(sessionStorage.getItem("email"));
  const countryId = JSON.parse(sessionStorage.getItem("selectedCountryId"));
  const stateId = JSON.parse(sessionStorage.getItem("selectedStateId"));
  const LGA = JSON.parse(sessionStorage.getItem("selectedLGA"));

  const handleFileChange = (event) => {
    console.log("Event:", event); // Log the event object
    const selectedFile = event.target.files[0];
    console.log("Selected File:", selectedFile); // Log the selected file
    // Check for valid image type
    if (!selectedFile.type.match("image/.*")) {
      toast.error("Please select a valid image file");
      return;
    }
    toast.success("Uploaded successfully");
    setGuarantorFile(selectedFile);
    setImageName(selectedFile.name);
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = (e) => console.log(e.target.result);
  };

  const handleImageSelection = (event) => {
    event.stopPropagation();
    const selectedFile = event.target.files[0];
    if (!selectedFile.type.match("image/.*")) {
      toast.error("Please select a valid image file");
      return;
    }
    setImageFile(selectedFile);
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = (e) => {
      console.log(e.target.result);
    };
    reader.onerror = (error) => {
      console.error("Error reading file:", error);
      toast.error("Error reading file");
    };
  };

  const handleSave = async () => {
    setLoading(true);

    // Check if any field is missing
    if (
      !guarantorData.guarantor_name ||
      !guarantorData.guarantor_phone ||
      !guarantorData.guarantor_email ||
      !guarantorData.guarantor_id_type ||
      !personalListInfo.firstname ||
      !personalListInfo.middlename ||
      !personalListInfo.lastname ||
      !personalListInfo.address ||
      !personalListInfo.phone ||
      !personalListInfo.gender ||
      !personalListInfo.education ||
      !bankData.bankId ||
      !bankData.account_name ||
      !bankData.account_number ||
      !countryId ||
      !stateId ||
      !LGA ||
      !guarantorFile ||
      !id ||
      !imageFile ||
      !email
    ) {
      toast.error("Please fill in all the fields");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("guarantor_name", guarantorData.guarantor_name);
    formData.append("guarantor_phone", guarantorData.guarantor_phone);
    formData.append("guarantor_email", guarantorData.guarantor_email);
    formData.append("guarantor_id_type", guarantorData.guarantor_id_type);
    formData.append("firstname", personalListInfo.firstname);
    formData.append("middlename", personalListInfo.middlename);
    formData.append("lastname", personalListInfo.lastname);
    formData.append("address", personalListInfo.address);
    formData.append("phone", personalListInfo.phone);
    formData.append("gender", personalListInfo.gender);
    formData.append("education", personalListInfo.education);
    formData.append("bank_id", bankData.bankId);
    formData.append("account_name", bankData.account_name);
    formData.append("account_number", bankData.account_number);
    formData.append("country_id", countryId);
    formData.append("state_id", stateId);
    formData.append("lga", LGA);
    formData.append("guarantor_id", guarantorFile);
    formData.append("id", JSON.stringify(id));
    formData.append("image", imageFile);
    formData.append("email", email);
    console.log("formData", formData);

    console.log("id", id);
    try {
      const response = await axios.post(UPDATE_KYC, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        toast.success("KYC form submitted successfully");
        navigate("/");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error submitting KYC form:", error);
      console.log("error.response.data:", error);
      toast.error("Error submitting KYC form");
    }

    setImageFile(null);
    setImageUrl(null);
  };

  console.log(guarantorData);
  console.log("guarantorFile:", guarantorFile);
  console.log("guarantorData:", guarantorData);
  console.log("personalListInfo:", personalListInfo);
  console.log("bankData:", bankData);
  console.log("id:", id);

  return (
    <div className="flex flex-col items-center justify-center h-screen relative">
      {step === 1 && (
        <div className="flex flex-col items-center bg-white">
          <div className="flex flex-col items-center bg-white p-4 border border-blue-500 rounded">
            <label
              htmlFor="guarantor-file"
              className="flex items-center gap-2 cursor-pointer text-l font-bold"
            >
              <FontAwesomeIcon
                icon={faPaperclip}
                className="text-[#7563d0] text-xl font-bold"
              />
              Upload Guarantor Document
            </label>
            <div>{imagename && imagename + " file uploaded"}</div>
            <input
              id="guarantor-file"
              type="file"
              className="hidden"
              onChange={handleFileChange}
            />
          </div>
          <button
            onClick={() => {
              if (imagename) {
                setStep(2);
              } else {
                toast.error("Kindly upload a document");
              }
            }}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-20"
          >
            Next
          </button>
        </div>
      )}

      {step === 2 && (
        <>
          <div className="mb-10 flex justify-center items-center">
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={() => setStep(1)}
              className="absolute top-20 left-5 text-3xl cursor-pointer"
            />
            <div className="flex flex-col items-center bg-white p-4 border border-blue-500 rounded">
              <label
                htmlFor="profile-file"
                className="font-bold cursor-pointer"
              >
                <input
                  type="file"
                  accept="image/*"
                  id="profile-file"
                  onChange={handleImageSelection}
                  hidden
                />
                <FontAwesomeIcon
                  icon={faPaperclip}
                  className="text-[#7563d0] text-xl font-bold cursor-pointer"
                />
                Upload Profile Image
              </label>
              <div>{imageFile && imageFile.name}</div>
            </div>
          </div>
          {imageFile && (
            <div className="text-center mb-2">
              <img
                src={URL.createObjectURL(imageFile)}
                alt="Selected"
                className="w-20 h-20 rounded-lg"
              />
            </div>
          )}
          <button
            onClick={handleSave}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-20"
          >
            {loading ? "Loading..." : "Submit"}
          </button>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </>
      )}
    </div>
  );
};
export default Capture;
