import React, { useState, useRef } from "react";
import axiosInstance from "../utils/AxiosInstance";
import { COMPETITION_IMAGE } from "../utils/constant";
import Sidebar1 from "../components/sidebar1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import AddButton from "./addbutton";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CompleteVisitButton from "./Completevisitbutton";

const CompetitionImage = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const handleFileInput = useRef(null);
  const [loadingText, setLoadingText] = useState(false); // State for loading indicator
  const navigate = useNavigate(); // Hook for navigation

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedImages((prevSelectedImages) => [
      ...prevSelectedImages,
      ...files,
    ]);
    console.log(selectedImages);
  };

  const removeImage = (index) => {
    const newSelectedImages = [...selectedImages];
    newSelectedImages.splice(index, 1);
    setSelectedImages(newSelectedImages);
  };

  const handleDescriptionChange = (newDescriptions) => {
    console.log("Descriptions:", newDescriptions);
    setDescriptions(newDescriptions);
  };

  const handleSubmit = async (event) => {
    setLoadingText(true);
    event.preventDefault();

    // Check if any image is selected
    if (selectedImages.length === 0) {
      // Show error message using toast
      toast.error("Please select at least one image.");
      setLoadingText(false);
      return;
    }

    if (descriptions.some((desc) => !desc || desc.trim() === "")) {
      toast.error("Please enter all descriptions.");
      setLoadingText(false);
      return;
    }

    // Retrieve schedule_id from sessionStorage
    const scheduleId = JSON.parse(sessionStorage.getItem("schedule_id"));
    const visitSchedules = JSON.parse(sessionStorage.getItem("visitSchedules"));
    const selectedSchedule = visitSchedules.find(
      (schedule) => schedule.schedule_id === scheduleId
    );
    console.log(scheduleId, "schedule_id");

    // Create FormData object
    const formData = new FormData();
    formData.append("schedule_id", scheduleId);
    formData.append("outlet_id", selectedSchedule.outlet_id);
    descriptions.forEach((desc, index) => {
      formData.append("description[]", desc.trim());
    });
    selectedImages.forEach((image, index) => {
      formData.append("visit_image[]", image);
    });
    console.log("schedule_id:", formData.get("schedule_id"));
    console.log("outlet_id:", formData.get("outlet_id"));
    console.log("visit_image[]");
    console.log(descriptions, "desc.trim()");

    try {
      const response = await axiosInstance.post(COMPETITION_IMAGE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Response:", response.data);
      toast.success("Completed");
      navigate("/competitionnote");

      // Handle success response
    } catch (error) {
      toast.error(
        "Error in API call:",
        error.response || error.message || error
      );
      console.error("Error:", error);
      setLoadingText(false);
      // Handle error response
    }
    setLoadingText(false);
  };

  return (
    <div>
      <div style={{ width: "100%", marginBottom: "20px" }}>
        <Sidebar1 />
      </div>

      <div
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: "30px" }}>
            <label style={{ color: "blue" }}>Outlet Image</label>
            <input
              type="file"
              accept="image/*"
              multiple
              ref={handleFileInput}
              onChange={handleImageChange}
            />
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {selectedImages.map((image, index) => (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={URL.createObjectURL(image)}
                    alt="Selected"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      background: "red",
                      color: "white",
                      border: "none",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    X
                  </button>
                </div>
              ))}
            </div>
            <AddButton handleDescriptionChange={handleDescriptionChange} />
          </div>
          <div className="flex justify-center gap-4">
            <CompleteVisitButton
              title={loadingText ? "loading...." : "Complete Visit"}
            />
            <button className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              <FontAwesomeIcon icon={faShareNodes} className="mr-2" />
              SHARE
            </button>
          </div>
        </form>

        {/* Media queries */}
        <style jsx>{`
          @media screen and (max-width: 768px) {
            .container {
              // Mobile styles
            }
          }
        `}</style>

        {/* Additional styles */}
        <style>{`
          body { font-family: Arial, Helvetica, sans-serif; }
          * { box-sizing: border-box; }
          input[type=text], select, textarea {
            width: 100%;
            padding: 12px;
            border: none;
            border-bottom: 1px solid #ccc;
            border-radius: 4px;
            margin-top: 6px;
            margin-bottom: 16px;
            resize: vertical;
          }
          input[type=submit] {
            background-color: #04AA6D;
            color: white;
            padding: 12px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
          }
          input[type=submit]:hover {
            background-color: #45a049;
          }
          .container {
            border-radius: 5px;
            background-color: #f7fafc;
            padding: 20px;
            width: 80%; /* Adjust width as needed */
            margin: auto; /* Center the container horizontally */
          }
        `}</style>
      </div>
    </div>
  );
};

export default CompetitionImage;
