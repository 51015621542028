import React, { useState, useEffect } from "react";
import { BRANDS, GET_PRODUCTS, SAVE_INVENTORY } from "../../utils/constant";
import axiosInstance from "../../utils/AxiosInstance";
import InventoryPop from "./inventorypopup";
import { toast } from "react-toastify";

const InventoryModal = () => {
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formData, setFormData] = useState({});
  const [loadingText, setLoadingText] = useState(false);

  useEffect(() => {
    const fetchBrand = async () => {
      try {
        const response = await axiosInstance.get(BRANDS);
        const data = response.data.data;
        setBrands(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching brand list:", error);
      }
    };

    fetchBrand();
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      if (!selectedBrand) return;
      try {
        const response = await axiosInstance.get(GET_PRODUCTS, {
          params: { brand_id: selectedBrand },
        });
        const data = response.data.data;
        setProducts(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching product list:", error);
      }
    };

    fetchProduct();
  }, [selectedBrand]);

  const handleBrandSelect = (brandId) => {
    setSelectedProduct(true); // Reset selected product when a brand is changed
    setSelectedBrand(brandId);
  };

  const handleSubmit = async () => {
    setLoadingText(true);
    const stock = sessionStorage.getItem("selectedStock");
    const visitSchedules = JSON.parse(sessionStorage.getItem("visitSchedules"));
    const scheduleId = JSON.parse(sessionStorage.getItem("schedule_id"));
    const selectedSchedule = visitSchedules.find(
      (schedule) => schedule.schedule_id === scheduleId
    );
    console.log("FormData before submission:", formData);
    const submitData = new FormData();
    const visitProductData = [];

    Object.keys(formData).forEach((productId) => {
      const productData = formData[productId];

      visitProductData.push({
        product_id: productId,
        unit: productData.unit,
      });
    });

    submitData.append("schedule_id", scheduleId);
    submitData.append("outlet_id", selectedSchedule.outlet_id);
    submitData.append("brand_id", selectedBrand);
    submitData.append("visit_product_data", JSON.stringify(visitProductData));
    submitData.append("stock_type", stock);

    try {
      const response = await axiosInstance.post(SAVE_INVENTORY, submitData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Response:", response.data);
      setSelectedProduct(null);
      toast.success("Inventory data saved successfully");
      setLoadingText(false);
      // Handle success (e.g., close modal, show notification)
    } catch (error) {
      toast.error(
        "Error in API call:",
        error.response || error.message || error
      );
      console.error("Error saving Inventory data:", error);
      setLoadingText(false);
      // Handle error (e.g., show error message)
    }
  };

  return (
    <div className="container h-[400px] overflow-y-scroll">
      {
        <form className="h-full flex flex-col justify-between items-">
          <div>
            <label htmlFor="brands" style={{ color: "blue" }}></label>
            <select
              id="brands"
              name="Select brands"
              value={selectedBrand}
              onChange={(e) => handleBrandSelect(e.target.value)}
              style={{ marginBottom: "10px" }}
            >
              <option value="select brand">Select Brand</option>
              {brands.map((brand) => (
                <option key={brand.id} value={brand.id}>
                  {brand.brand_name}
                </option>
              ))}
            </select>
            {selectedProduct && (
              <InventoryPop
                products={products}
                formData={formData}
                setFormData={setFormData}
                setSelectedProduct={setSelectedProduct} // Pass the function to close the modal
              />
            )}
          </div>

          <div className="button-container">
            <button
              className="flex items-end my-[20px] justify-center"
              type="button"
              style={{
                width: "100%",
                padding: "5px 100px",
                backgroundColor: "blue",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={handleSubmit}
            >
              {loadingText ? "loading..." : "SAVE"}
            </button>
          </div>
        </form>
      }

      <style jsx>{`
        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;
        }
        .button-container {
          margin-top: auto; /* Push the container to the bottom */
        }
        @media screen and (max-width: 768px) {
          .container {
            // Mobile styles
          }
        }
      `}</style>

      {/* Additional styles */}
      <style>{`
        body { font-family: Arial, Helvetica, sans-serif; }
        * { box-sizing: border-box; }
        input[type=text], select, textarea {
          width: 100%;
          padding: 12px;
          height: 50px;
          border: none;
          border-bottom: 1px solid #ccc;
          border-radius: 4px;
          margin-top: 6px;
          margin-bottom: 16px;
          resize: vertical;
        }
        input[type=submit] {
          background-color: #04AA6D;
          color: white;
          padding: 12px 20px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }
        input[type=submit]:hover {
          background-color: #45a049;
        }
        .container {
          border-radius: 5px;
          background-color: #f2f2f2;
          padding: 20px;
          width: 80%; /* Adjust width as needed */
          margin: auto; /* Center the container horizontally */
        }
      `}</style>
    </div>
  );
};

export default InventoryModal;
