import React from "react";

const OrderingPopup = ({
  selectedProduct,
  setSelectedProduct,
  products,
  formData,
  setFormData,
}) => {
  const handleChange = (e, productId, field) => {
    const value = field === "availability" ? e.target.checked : e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [productId]: {
        ...prevData[productId],
        [field]: value,
      },
    }));
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={() => setSelectedProduct(null)}>
          &times;
        </span>
        {products.map((pro) => {
          return (
            <div
              key={pro.id}
              className="flex items-start p-1 border rounded-lg shadow-lg mb-1 py-3 px-[10px]"
            >
              <div className="flex-shrink-0 mr-4">
                <img
                  src={`https://alphageekimage.fra1.digitaloceanspaces.com${pro.product_image}`}
                  className="w-12 h-12"
                />
              </div>
              <div>
                <h2 className="text-l font-semibold">{pro.product_name}</h2>
                <div className="mt-2 flex items-center">
                  <input
                    type="checkbox"
                    id={`availability-${pro.id}`}
                    name="availability"
                    className="mr-1"
                    checked={formData[pro.id]?.availability || false}
                    onChange={(e) => handleChange(e, pro.id, "availability")}
                  />
                  <label
                    className="text-[10px] text-blue-300"
                    htmlFor={`availability-${pro.id}`}
                  >
                    Order
                  </label>
                </div>
                <div className="flex mt-1 space-x-1">
                  <input
                    type="number"
                    id={`case-${pro.id}`}
                    name="case"
                    min="1"
                    placeholder="Case"
                    className="border rounded p-1 w-24"
                    value={formData[pro.id]?.case || ""}
                    onChange={(e) => handleChange(e, pro.id, "case")}
                  />
                  <input
                    type="number"
                    id={`quantity-${pro.id}`}
                    name="quantity"
                    min="1"
                    placeholder="Unit"
                    className="border rounded p-1 w-24"
                    value={formData[pro.id]?.quantity || ""}
                    onChange={(e) => handleChange(e, pro.id, "quantity")}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OrderingPopup;
