import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import axiosInstance from "../utils/AxiosInstance";
import { CREATE_SCHEDULE } from "../utils/constant";
import { USER_OUTLETS } from "../utils/constant";
import { useNavigate } from "react-router-dom";
import TitleButton from "../components/buttons";
import ModalLoader from "../components/modal/loader";
import { toast } from "react-toastify";

const CreateSchedule = () => {
  const [outlets, setOutlets] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState("");
  const [scheduleDate, setScheduleDate] = useState("");
  const [scheduleTime, setScheduleTime] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOutletList = async () => {
      try {
        const response = await axiosInstance.get(USER_OUTLETS);
        const data = response.data.data;
        setOutlets(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching outlet list:", error);
        setLoading(false);
      }
    };

    fetchOutletList();
  }, []);

  const country = JSON.parse(sessionStorage.getItem("selectedCountryId"));
  const state = JSON.parse(sessionStorage.getItem("selectedStateId"));
  const LGA = JSON.parse(sessionStorage.getItem("selectedLGA"));
  const Onga = JSON.parse(sessionStorage.getItem("selectedOnga"));

  const validateForm = () => {
    let errors = {};

    if (!selectedOutlet) {
      errors.selectedOutlet = "Please select an outlet";
      toast.error("Please select an outlet");
    }

    if (!scheduleDate) {
      errors.scheduleDate = "Please enter a schedule date";
      toast.error("Please enter a schedule date");
    }

    if (!scheduleTime) {
      errors.scheduleTime = "Please enter a schedule time";
      toast.error("Please enter a schedule time");
    }

    return Object.keys(errors).length === 0;
  };

  const getPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      setLoadingText(true);
      setLoading(true);

      try {
        const position = await getPosition();
        const { latitude, longitude } = position.coords;

        const formData = new FormData();
        formData.append("outlet_id", selectedOutlet);
        formData.append("schedule_date", scheduleDate);
        formData.append("schedule_time", scheduleTime);
        formData.append("gio_lat", latitude);
        formData.append("gio_long", longitude);
        formData.append("country_id", country);
        formData.append("state_id", state);
        formData.append("region_id", "1");
        formData.append("location_id", LGA);
        formData.append("onga_id", Onga);

        try {
          const response = await axiosInstance.post(CREATE_SCHEDULE, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          console.log("Schedule created:", response.data);
          toast.success("Successfully created");
          navigate("/visit-schedule");
        } catch (error) {
          console.error(
            "Error in API call:",
            error.response || error.message || error
          );

          if (error.response) {
            console.error("Response status:", error.response.status);
            console.error("Response data:", error.response.data);
          }

          toast.error("An error occurred. Please try again.");
        }

        setLoading(false);
        setLoadingText(false);
      } catch (error) {
        console.error("Error in geolocation:", error);
        setLoading(false);
        setLoadingText(false);
      }
    }
  };

  return (
    <div>
      <ModalLoader visible={loading} />
      <div style={{ width: "100%", marginBottom: "20px" }}>
        <Sidebar />
      </div>

      <div
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <form onSubmit={handleSubmit}>
          <label htmlFor="outlet" style={{ color: "blue" }}>
            Select Outlet
          </label>
          <select
            id="outlet"
            value={selectedOutlet}
            onChange={(e) => setSelectedOutlet(e.target.value)}
            style={{ marginBottom: "10px" }}
          >
            <option value="">Select Outlet</option>
            {outlets.map((outlet) => (
              <option key={outlet.outlet_name} value={outlet.outlet_id}>
                {outlet.outlet_name}
              </option>
            ))}
          </select>

          <label htmlFor="scheduleDate" style={{ color: "blue" }}>
            Schedule Date
          </label>
          <input
            type="date"
            id="scheduleDate"
            value={scheduleDate}
            onChange={(e) => setScheduleDate(e.target.value)}
            style={{ marginBottom: "10px" }}
          />

          <label htmlFor="scheduleTime" style={{ color: "blue" }}>
            Schedule Time
          </label>
          <input
            type="time"
            id="scheduleTime"
            value={scheduleTime}
            onChange={(e) => setScheduleTime(e.target.value)}
            style={{ marginBottom: "10px" }}
          />

          <TitleButton
            title={loadingText ? "Loading" : "Save"}
            handleSubmit={handleSubmit}
          />
        </form>
      </div>

      <style jsx>{`
        @media screen and (max-width: 768px) {
          .container {
            // Mobile styles
          }
        }
      `}</style>

      {/* Additional styles */}
      <style>{`
        body { font-family: Arial, Helvetica, sans-serif; }
        * { box-sizing: border-box; }
        input[type=text], select, textarea {
          width: 100%;
          padding: 12px;
          border: none;
          border-bottom: 1px solid #ccc;
          border-radius: 4px;
          margin-top: 6px;
          margin-bottom: 16px;
          resize: vertical;
        }
        input[type=submit] {
          background-color: #04AA6D;
          color: white;
          padding: 12px 20px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }
        input[type=submit]:hover {
          background-color: #45a049;
        }
        .container {
          border-radius: 5px;
          background-color: #f2f2f2;
          padding: 20px;
          width: 80%; /* Adjust width as needed */
          margin: auto; /* Center the container horizontally */
        }
      `}</style>
    </div>
  );
};

export default CreateSchedule;
