import React from "react";

const ProductModal = ({ selectedProduct, setSelectedProduct, products, formData, setFormData }) => {
  const handleChange = (e, productId, field) => {
    const value = field === 'availability' ? e.target.checked : e.target.value;
    setFormData(prevData => ({
        ...prevData,
        [productId]: {
            ...prevData[productId],
            [field]: value
        }
    }));
};


    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={() => setSelectedProduct(null)}>&times;</span>
                {
                    products.map((pro, i) => {
                        return (
                            <div key={i} className="flex items-start space-x-1 p-1 border rounded-lg shadow-lg mb-1 py-3 px-[10px]">
                                <div className="flex-shrink-0 mr-4">
                                    <img src={`https://alphageekimage.fra1.digitaloceanspaces.com${pro.product_image}`} className="w-12 items-center" />
                                </div>
                                <div>
                                    <h2 className="text-l font-semibold">{pro.product_name}</h2>
                                    <div className="flex justify-between flex-col sm:flex-row">
                                        <div className="mt-2 flex items-center">
                                            <input
                                                type="checkbox"
                                                id={`availability-${pro.id}`}
                                                name="availability"
                                                className="mr-1"
                                                checked={formData[pro.id]?.availability || false}
                                                onChange={(e) => handleChange(e, pro.id, 'availability')}
                                            />
                                            <label className="text-[10px] text-blue-300" htmlFor={`availability-${pro.id}`}>Available</label>
                                        </div>
                                        <div className="mt-1 flex items-center space-x-1 sm:flex-col">
                                            <select
                                                id={`quantity-${pro.id}`}
                                                name="quantity"
                                                className="border rounded p-1"
                                                value={formData[pro.id]?.quantity || ''}
                                                onChange={(e) => handleChange(e, pro.id, 'quantity')}
                                            >
                                                <option value="">Select Option</option>
                                                <option value="Quantity">Quantity</option>
                                                <option value="Facing">Facing</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mt-1">
                                        <input
                                            type="number"
                                            id={`unit-${pro.id}`}
                                            name="unit"
                                            min="1"
                                            placeholder="Unit"
                                            className="border rounded p-1 w-48"
                                            value={formData[pro.id]?.unit || ''}
                                            onChange={(e) => handleChange(e, pro.id, 'unit')}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default ProductModal;
