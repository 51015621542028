import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/AxiosInstance";
import Sidebar from "../../components/Sidebar";
import { STOCK_TYPES } from "../../utils/constant";

const InventoryPage = () => {
  const [stock, setStock] = useState([]);
  const [selectedStock, setSelectedStock] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStock = async () => {
      try {
        const response = await axiosInstance.get(STOCK_TYPES);
        const data = response.data.data;
        setStock(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching brand list:", error);
      }
    };

    fetchStock();
  }, []);

  useEffect(() => {
    sessionStorage.setItem("selectedStock", selectedStock);
    console.log(sessionStorage.getItem("selectedStock"));
    if (selectedStock) {
      navigate("/inventory-page2");
    }
  }, [selectedStock]);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "50vh" }}>
      <div className="bg-blue-400">
        <Sidebar />
      </div>

      <div
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <form>
          <select
            id="select-stock-type"
            name="select-stock-type"
            value={selectedStock}
            required
            onChange={(e) => setSelectedStock(e.target.value)}
            style={{ marginBottom: "10px" }}
          >
            <option value="">Select stock type</option>
            {stock.map((stock) => (
              <option key={stock.id} value={stock.stock_type}>
                {stock.stock_type}
              </option>
            ))}
          </select>
        </form>
      </div>

      <style jsx>{`
        @media screen and (max-width: 768px) {
          .container {
            // Mobile styles
          }
        }
      `}</style>

      {/* Additional styles */}
      <style>{`
        body { font-family: Arial, Helvetica, sans-serif; }
        * { box-sizing: border-box; }
        input[type=text], select, textarea {
          width: 100%;
          padding: 12px;
          border: none;
          border-bottom: 1px solid #ccc;
          border-radius: 4px;
          margin-top: 6px;
          margin-bottom: 16px;
          resize: vertical;
        }
        input[type=submit] {
          background-color: #04AA6D;
          color: white;
          padding: 12px 20px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }
        input[type=submit]:hover {
          background-color: #45a049;
        }
        .container {
          border-radius: 5px;
          background-color: #f2f2f2;
          padding: 20px;
          width: 80%; /* Adjust width as needed */
          margin: auto; /* Center the container horizontally */
        }
      `}</style>
    </div>
  );
};

export default InventoryPage;
