import React from "react";

const InventoryPop = ({
  selectedProduct,
  setSelectedProduct,
  products,
  formData,
  setFormData,
}) => {
  const handleChange = (e, productId) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [productId]: {
        ...prevData[productId],
        unit: value,
      },
    }));
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={() => setSelectedProduct(null)}>
          &times;
        </span>
        {products.map((pro, i) => {
          return (
            <div
              key={i}
              className="flex items-start space-x-1 p-1 border rounded-lg shadow-lg mb-1 py-3 px-[10px]"
            >
              <div className="flex-shrink-0 mr-4">
                <img
                  src={`https://alphageekimage.fra1.digitaloceanspaces.com${pro.product_image}`}
                  className="w-12 items-center"
                  // alt={pro.product_name}
                />
              </div>
              <div>
                <h2 className="text-l font-semibold">{pro.product_name}</h2>
                <div className="mt-1">
                  <label
                    className="text-[10px] text-blue-300"
                    htmlFor={`unit-${pro.id}`}
                  >
                    Quantity
                  </label>
                </div>
                <div>
                  <input
                    type="number"
                    id={`unit-${pro.id}`}
                    name="unit"
                    min="1"
                    placeholder="Unit"
                    className="border rounded p-1 w-48"
                    value={formData[pro.id]?.unit || ""}
                    onChange={(e) => handleChange(e, pro.id)}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InventoryPop;
