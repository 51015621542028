import React from "react";
import Sidebar from "../../components/Sidebar";
import Dashboard from "../../components/dashboard";

const InventoryCheck = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "50vh" }}>
      <div className="bg-blue-400">
        <Sidebar />
      </div>

      <div>
        <Dashboard LINK={"/inventory-page1"} />
      </div>
    </div>
  );
};

export default InventoryCheck;
