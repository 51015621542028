import React, { useState } from "react";
import CompleteVisitButton from "../../components/Completevisitbutton";
import Sidebar1 from "../../components/sidebar1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ProductNote = () => {
  const [newNote, setNote] = useState("");
  const [loadingText, setLoadingText] = useState(false);
  const navigate = useNavigate();

  const handleNote = (e) => {
    const newNote = e.target.value;
    setNote(newNote);
    console.log(setNote);
  };

  return (
    <>
      <div style={{ width: "100%", marginBottom: "20px" }}>
        <Sidebar1 />
      </div>

      <div
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <form>
          <label style={{ color: "blue" }}>Note (Feed Back)</label>
          <textarea
            id="note"
            name="note"
            placeholder="Write Something..."
            onChange={handleNote}
            required
            style={{ height: "100px", marginBottom: "100px" }}
          />
          <div className="flex justify-center gap-4">
            <CompleteVisitButton
              title={loadingText ? "loading...." : "Complete Visit"}
            />
            <button className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              <FontAwesomeIcon icon={faShareNodes} className="mr-2" />
              SHARE
            </button>
          </div>
        </form>
      </div>
      {/* Media queries */}
      <style jsx>{`
        @media screen and (max-width: 768px) {
          .container {
            // Mobile styles
          }
        }
      `}</style>

      {/* Additional styles */}
      <style>
        {`
        body { font-family: Arial, Helvetica, sans-serif; }
        * { box-sizing: border-box; }
        input[type=text], select, textarea {
          width: 100%;
          padding: 12px;
          border: none;
          border-bottom: 1px solid #ccc;
          border-radius: 4px;
          margin-top: 6px;
          margin-bottom: 16px;
          resize: vertical;
        }
        input[type=submit] {
          background-color: #04AA6D;
          color: white;
          padding: 12px 20px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }
        input[type=submit]:hover {
          background-color: #45a049;
        }
        .container {
          border-radius: 5px;
          background-color: #f7fafc;
          padding: 20px;
          width: 80%; /* Adjust width as needed */
          margin: auto; /* Center the container horizontally */
        }
      `}
      </style>
    </>
  );
};

export default ProductNote;
